<template>
  <v-fade-transition :style="`background-color: ${wsBACKGROUND}`" mode="out-in">

    <div v-if="loggedIn" :style="`background-color: ${wsBACKGROUND}`"   >


      <v-sheet v-if="!SM" id="dash_content"  :color="wsBACKGROUND" class=" fill-height  " >

        <v-row no-gutters  style="flex-wrap: nowrap; height: 100% !important" class="fill-height"  >

          <v-col :style="sideBarStyle" class="flex-grow-0 flex-shrink-0 sideTransition fill-height">
            <side-bar />
          </v-col>

          <v-col  style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0">
            <v-sheet :color="wsDASHBACKGROUND" class="fill-height">
              <router-view style="overflow-y: unset;"></router-view>
            </v-sheet>
          </v-col>

        </v-row>

      </v-sheet>
      <sideBarBusinessMobile   v-if=" SM && $store.state.dashboard.sideMenuMobile" admin />
      <div v-if="SM" class="fill-height" >

        <headerDash />

        <v-sheet  class="fill-height">
          <router-view ></router-view>
        </v-sheet>
        <footerDashMobile v-if="false" />
      </div>


      <ws-dialog v-if="$store.state.dashboard.showLogoutConfirm"
                 v-model="$store.state.dashboard.showLogoutConfirm"
                 :title="$t('Logout')"
                 :save-text="$t('Yes')"
                 @delete="logout"
                 display-confirm-delete
                 close-delete
                 delete-text="Exit"
                 portal
      >
        <template #confirm-delete>
          <h3 class="my-12 text-center ">{{ $t('AreYouSureYouWantToQuit') }}</h3>
        </template>
      </ws-dialog>
  </div>

    <v-sheet v-else-if="!loading" :color="wsBACKGROUND" class="d-flex align-center justify-center" >
      <v-sheet class=" pa-6 px-12" :class=" displayHide(['sm']) ? 'wsRoundedHalf' : '' "
               :width="displayHide(['sm']) ? 500 : '100vh'"
               :height="displayHide(['sm']) ? '' : '100vh'">

        <h3 v-if="displayHide(['sm'])"  style="line-height: 1.2" class="mt-3  font-weight-medium text-center"> {{ $t('PleaseLoginToProceed') }}  </h3>
        <h4 v-else  style="line-height: 1.2" class="mt-3  font-weight-medium text-center"> {{ $t('PleaseLoginToProceed') }}  </h4>
        <auth-window-new
            no-auth-action
            display-login
            width="100%"
            class="mt-16" />
      </v-sheet>
    </v-sheet>
    <v-sheet v-else  class="d-flex align-center justify-center fill-height">
      <div class="d-block">
        <v-progress-circular size="48" :indeterminate="loading" />
        <h2 class="text-center font-weight-light">{{ $t('loading') }}</h2>
      </div>
    </v-sheet>

  </v-fade-transition>

</template>

<script>

import sideBar from "@/components/pages/dashboard/UI/sidebar/sideBar";
import {mapMutations, mapState} from "vuex";
import authWindowNew from "@/components/auth/authWindowNew";
import sideBarBusinessMobile from "@/components/pages/businessDashboard/UI/sidebarBusiness/sideBarBusinessMobile";
import headerDash from "@/components/pages/dashboard/UI/headerDash";
import footerDashMobile from "@/components/pages/dashboard/UI/footerDashMobile";
import referral from "@/mixins/referral";
export default {
  name: 'HelloWorld',
  mixins : [referral],
  components: {
    sideBar,
    authWindowNew,
    sideBarBusinessMobile,
    headerDash,
    footerDashMobile,
  },
  data() {
    return {
      menuActive: false,
      loading : true,
    }
  },
  computed: {
    ...mapState('dashboard', ['sideMenu','pageTitle','breadcrumbs','modalDash']),
    ...mapState('auth', ['loggedIn']),

    sideBarStyle() {
      let width = this.sideMenu ? 280 : 75;
      return `min-width: ${width}px; max-width: ${width}px; height: 100% !important; transition : all 0.3s ease`
    },

    bodyStyleClass() {
      if ( this.sideMenu ) { return 'mainWithMenu' }
      return this.displayShow(['sm']) ? 'mainMobile' : 'main'
    }
  },
  methods : {
    ...mapMutations('auth',['LOGOUT']),
    logout() {
      this.$store.state.dashboard.showLogoutConfirm = false
      this.LOGOUT(this.$router)
      this.$router.push(this.localeLink(''))
    },
  },
  mounted() {
    this.no
    this.CHECK_PARTNER_REFFERAL()
    this.loading = false
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.wrapper {
  background-color: whitesmoke;
  height:100%;
}
.main {
  margin-left: 60px;
  transition: margin-left 0.3s ease-out;
}
.mainWithMenu {
  margin-left: 250px !important;
  transition: all 0.3s ease-out;
}

.content {
  overflow-y: unset;
  transition: all 0.3s ease-out;
}


</style>
