<template>
  <div>
    <v-menu offset-y max-width="350" >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" >
          <v-badge  :content="recentEventsCount"
                    :value="recentEventsCount"
                    :color="wsATTENTION"
                    overlap >
            <v-icon>mdi-bell-outline</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-sheet >

        <router-link :to="businessDashLink('notifications')" class="noUnderline pointer">
        <div class="d-flex justify-space-between align-center px-4 pt-3 mb-3">
          <div class="d-flex align-center">
            <v-icon :color="wsACCENT" class="mr-2">mdi-bell-outline</v-icon>
            <h5 class="black--text font-weight-medium hover-underline" >{{ $t('NotificationCenter') }}</h5>
          </div>
        </div>
        </router-link>
        <v-divider></v-divider>

        <template v-for="(event,i) in recentEvents" >
          <v-hover v-slot="{hover}"  :key="i">
            <router-link :to="businessDashLink(event.router_link)" class="noUnderline">
              <v-sheet
                  width="350"  :color="hover ? wsLIGHTCARD : ''"
                  v-ripple class="d-flex align-center py-3 px-4 pointer">
                <v-avatar size="42" :color="!hover ? wsLIGHTCARD : 'white'" class="mr-5">
                  <v-icon>{{ getEventIcon(event.type) }}</v-icon>
                </v-avatar>
                <div>
                  <h5 class="font-weight-medium">{{ event.text }}</h5>
                  <h6 class="font-weight-light"> <v-icon x-small color="grey lighten-1">mdi-clock</v-icon> {{ PARSE_TIME_STAMP(event.date_created , true)  }}</h6>
                </div>
              </v-sheet>
            </router-link>
          </v-hover>
        </template>
        <v-sheet width="350" v-if="recentEvents.length === 0" class="px-4 py-6">
          <h5 class="font-weight-light text-center"> {{ $t('YouHaveNoNewEvents') }} </h5>
        </v-sheet>


      </v-sheet>
    </v-menu>



  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "notificationsDropdown",
  computed : {
    ...mapState('events', [
        'recentEvents',
    ])
  },
  methods : {
    ...mapActions('events', [
      'GET_RECENT_EVENTS',
    ]),

  },
  mounted() {
    this.GET_RECENT_EVENTS()
  }
}
</script>

<style scoped>

</style>