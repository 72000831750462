<template>
  <v-sheet :color="wsDASHBACKGROUND" class=" ">

    <!-- DESKTOP SIZE-->
    <div v-if="displayHide(['sm'])"
          class="d-flex justify-end align-center py-5 ">
      <wsLangSelect class="mr-2"  />
      <notificationsDropdown />
      <messagesMenu id="dash_header_messages" class="mr-n6" />
      <userDropdown dashboard img_size="22" class="userDropdown" />
    </div>

    <!-- MOBILE SIZE-->
    <div class="d-flex justify-space-between align-center pl-3 pr-6 py-1 " v-else>
        <div @click="$store.state.dashboard.sideMenuMobile = true " class="pointer"  >
          <img v-if="$store.state.project === 'fine'"     height="40px" class="mt-3" alt="WeStudy" src="@/assets/brand/logo_fine_white.png" />
          <img v-if="$store.state.project === 'westudy'"  height="40px" class="mt-3" alt="WeStudy" src="@/assets/brand/logo_westudy_color.png" />
        </div>

      <v-btn v-if="displayShow(['sm'])"
             @click="$store.state.dashboard.sideMenuMobile = true "
             icon   color="grey darken-4">
        <v-icon> mdi-menu </v-icon>
      </v-btn>


    </div>

  </v-sheet>
</template>

<script>
import messagesMenu from "@/modules/messenger/components/messagesMenu";

import userDropdown from "@/components/UI/userDropdown";
import {mapMutations,mapState} from "vuex";
import wsLangSelect from "@/components/UI/wsLangSelect";
import notificationsDropdown from "@/components/UI/notificationsDropdown";
export default {
  name: "headerDash",
  components : {
    userDropdown,
    wsLangSelect,
    messagesMenu,
    notificationsDropdown
  },
  computed : {
    ...mapState('dashboard', ['sideMenu','pageTitle']),
  },
  methods: {
    ...mapMutations('dashboard', ['TOGGLE_SIDEBAR']),
  },
}
</script>

<style scoped>


.header {
  color: #2c3e50;
}
.userDropdown {
  margin : 0px 25px;
}

</style>